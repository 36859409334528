import { Outlet } from "@remix-run/react";

import * as Header from "~/ui/Header";

export default function AuthLayout() {
  return (
    <>
      <Header.Root>
        <Header.Content className="mx-auto w-full max-w-screen-lg">
          <Header.Title>
            <Header.Logo />
          </Header.Title>
        </Header.Content>
      </Header.Root>
      <main className="w-full mx-auto p-page max-w-screen-sm">
        <Outlet />
      </main>
    </>
  );
}
